@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;500;700&display=swap');

:root {
  --background-color: #0a0c10;  /* Darker main background */
  --surface-color: #13151a;  
  --primary-color: #58a6ff;
  --secondary-color: #30a14e;
  --on-background: #c9d1d9;
  --on-surface: #c9d1d9;
  --text-primary: #c9d1d9;
  --text-secondary: #8b949e;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'JetBrains Mono', monospace;
  background-color: var(--background-color);
  color: var(--on-background);
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.femboy-list-container {
  flex-grow: 1;
  background-color: var(--surface-color);
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.femboy-list-title {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
  font-weight: 700;
}

.femboy-list, .femboy-list-empty, .femboy-list-loading {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.femboy-list {
  list-style-type: none;
  padding: 0;
}

.femboy-list-empty, .femboy-list-loading {
  text-align: center;
  color: var(--text-secondary);
  padding: 2rem;
  background-color: var(--background-color);
  border-radius: 8px;
  margin-top: 2rem;
}

.femboy-item {
  background-color: var(--background-color);
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.femboy-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.femboy-name {
  font-weight: 700;
  color: var(--text-primary);
  font-size: 1.5rem;
}

.femboy-info {
  text-align: right;
}

.femboy-game {
  font-size: 1rem;
  color: var(--secondary-color);
  margin-bottom: 0.3rem;
}

.femboy-date {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.search-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto 2rem;
}

.search-input {
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  font-family: 'JetBrains Mono', monospace;
  color: var(--text-primary);
  background-color: var(--surface-color);
  border: none;
  border-radius: 8px;
  outline: none;
  transition: box-shadow 0.2s ease;
}

.search-input:focus {
  box-shadow: 0 0 0 2px var(--primary-color);
}

.search-input::placeholder {
  color: var(--text-secondary);
}

.loading-spinner {
  border: 4px solid var(--surface-color);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

.status-indicator-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
}

.status-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    position: relative;
}

.status-indicator.green {
    background-color: #4CAF50;
}

.status-indicator.orange {
    background-color: #FFA500;
}

.status-indicator:hover {
    transform: scale(1.1);
}

.status-tooltip {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    top: 125%;  /* Changed from bottom: 125% to top: 125% */
    right: 0;   /* Changed from left: 50% to right: 0 */
    transform: translateY(0);  /* Removed translateX */
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
}

.status-indicator:hover .status-tooltip {
    visibility: visible;
    opacity: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 600px) {
  .femboy-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .femboy-info {
    text-align: left;
    margin-top: 0.5rem;
  }
}
